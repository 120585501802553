<template>
  <app-dialog
    v-model="addToLibraryModal"
    :value.sync="addToLibraryModal"
    :disabled="!canWorkWithAddToLibrary.allowed"
    width="440"
    content-class="v-dialog__form">
    <template #activator="{ on, attrs }">
      <div
        class="px-0"
        v-bind="attrs"
        v-on="on">
        <app-icon-btn
          :access="canWorkWithAddToLibrary"
          icon-name="mdi-library-books"
          bottom
          icon-height="16px"
          icon-color="rgba(0, 0, 0, 0.54)"
          :show-tooltip="!canWorkWithAddToLibrary">
          Add To Library
        </app-icon-btn>
      </div>
    </template>
    <div class="dialog-content">
      <v-card>
        <v-card-title>
          <div class="d-flex align-center">
            <span>Add Collection To Custom Library</span>
          </div>
          <v-icon
            color="#fff"
            @click="cancel">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text
          class="items-wrapper">
          <div
            class="items">
            <div
              class="item-wrapper">
              <v-autocomplete
                v-model="addedItemsIds"
                :menu-props="{ contentClass: 'fixed-heigth-select-menu full-width-select-menu' }"
                item-text="name"
                item-value="id"
                multiple
                chips
                attach
                :items="defaultItemForSelect"
                placeholder="Choose Library">
                <template #item="{item,attrs: attrsSlot}">
                  <v-layout wrap>
                    <v-list-item-action>
                      <v-checkbox
                        v-model="attrsSlot.inputValue"
                        :class="{'unchecked-color':deleteCollectionOnLibrary.includes(item.id)}"
                        :color="addCollectionToLibrary.includes(item.id) ? 'green': ''"
                        :disabled="item.disabled" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        :style="[item.disabled ? { 'color': 'rgba(0, 0, 0, 0.38) !important' } : {}]">
                        {{
                          item.name
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-layout>
                </template>
              </v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          class="pt-6 d-flex justify-end">
          <template>
            <v-btn
              outlined
              @click="cancel">
              Cancel
            </v-btn>
            <v-btn
              color="blue"
              class="white--text"
              @click="add">
              Add
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </div>
  </app-dialog>
</template>
<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import { hasEditorAccess } from '@/utils';
import CollectionsApi from '@/services/graphql/collections';
import LibrariesApi from '@/services/graphql/libraries';
import { isEmpty } from 'lodash';
import { getUserRolesListById } from '@/utils/accessLayer';
import { TYPE_FOLLOWER } from '@/constants/userPermissions';
export default {
  name: 'CollectionAddToCustomLibrary',
  props: {
    id: {
      type: String,
      default: '',
    },
    librariesList: {
      type: Array,
      default: null,
    },
    libraryId: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      addToLibraryModal: false,
      addedItemsIds: [],
      libraryItemIds: [],
      nextToken: undefined,
    };
  },
  computed: {
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapGetters(['userId']),
    ...mapGetters('UserRoles', ['canAddCollectionToLibrary', 'findUserRoleInLibraryForActiveHeader']),
    ...mapGetters('Libraries', ['getDefaultLibraryId']),
    isFollowedProduct() {
      return this.item?.collectionGroup === 'followed' || this.item?.follow;
    },
    canWorkWithAddToLibrary() {
      const { userId } = this;
      const { type = '', members: users } = this.item || {
      };
      let role = type ?? this.findUserRoleInLibraryForActiveHeader;
      if (users) {
        role = getUserRolesListById({
          userId,
          users,
        });
        if (!role[0] && this.isFollowedProduct) {
          role = TYPE_FOLLOWER;
        }
      }
      return this.canAddCollectionToLibrary(role);
    },
    defaultItemForSelect() {
      return this.librariesList.filter(el => hasEditorAccess(el.type) && el.id !== this.getDefaultLibraryId);
    },
    addCollectionToLibrary() {
      return this.addedItemsIds.filter(id => !this.libraryItemIds.includes(id));
    },
    deleteCollectionOnLibrary() {
      return this.libraryItemIds.filter(id => !this.addedItemsIds.includes(id));
    },
  },
  watch: {
    async addToLibraryModal(val) {
      if (val) {
        this.listLibrariesWithCollection();
        if (!this.librariesList.length) {
          this.getLibrariesList();
        }
      } else {
        this.addedItemsIds = [];
        this.libraryItemIds = [];
        this.nextToken = undefined;
      }
    },
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      handleError: 'handleError',
      getLibrariesList: 'Libraries/getLibrariesList',
    }),
    async add() {
      this.spinner(true);
      try {
        const workspaceId = this.activeWorkspaceId;
        if (!isEmpty(this.deleteCollectionOnLibrary)) {
          for (let i = 0; i < this.deleteCollectionOnLibrary.length; i++) {
            await CollectionsApi.removeCollectionFromLibrary({
              collectionId: this.id,
              libraryId: this.deleteCollectionOnLibrary[i],
              workspaceId: workspaceId,
            });
          }
        }
        if (!isEmpty(this.addCollectionToLibrary)) {
          await CollectionsApi.addCollectionToLibrary({
            collectionId: this.id,
            libraryIds: this.addCollectionToLibrary,
            workspaceId,
          });
        }
      } catch (err) {
        console.log('addToLibrary', err);
      } finally {
        this.spinner(false);
        this.cancel();
      }
    },
    cancel() {
      this.addToLibraryModal = false;
      this.addedItemsIds = [];
    },
    async listLibrariesWithCollection() {
      this.spinner(true);
      try {
        const { nextToken } = this;
        let { data } = await LibrariesApi.listLibrariesWithCollection({
          workspaceId: this.activeWorkspaceId,
          collectionId: this.id,
          limit: 200,
          nextToken,
        });
        const { data: dataItems, nextToken: token } = data.response;
        this.nextToken = token;
        const itemsIds = dataItems.map(e => e.id);
        this.addedItemsIds = [...this.addedItemsIds, ...itemsIds];
        this.libraryItemIds = [...this.libraryItemIds, ...itemsIds];
        if (this.nextToken) await this.listLibrariesWithCollection();
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.items-wrapper {
  min-height: 135px;
}
::v-deep .v-card__actions {
  margin-top: 160px;
}
::v-deep .v-list {
  height: 163px;
}
::v-deep .v-select__selections {
  max-height: 76px;
  overflow: auto;
}
</style>
