<template>
  <div class="comments-wrapper">
    <p
      v-if="data.nextToken"
      class="medium-p mb-0 mx-8 mb-2 text-center show-more-comments"
      @click="$emit('getComments', {
        scanIndexForward: false,
      })">
      <span class="pointer">
        Show Earlier Comments
      </span>
    </p>
    <!-- list of comments -->
    <div
      v-for="(comment, index) in filterComments"
      :id="`commentId_${comment.id}`"
      :key="comment.id"
      ref="commentsRows"
      data-test="project_comment_block"
      class="comments-wrapper-border-items comment"
      :class="{
        'mb-0' : index === data.comments.length -1,
        'comments-wrapper-border-items__edited': editedRowId === comment.id,
        'comments-wrapper-border-items__scrolled-comment': scrolledComment === comment.id,
      }"
      @mouseover="!$route.query.version ? hoverRowId=comment.id : hoverRowId=null"
      @mouseleave="hoverRowId=null">
      <!-- comment title -->
      <div class="comment-title">
        <div class="comment-author">
          <!-- image -->
          <AvatarImage
            :avatar="avatars[getCreatorProp({ comment })]"
            :avatar-key="getCreatorProp({ comment })"
            :color="getCreatorColor({ comment })"
            :size="25" />

          <!-- name + designation -->
          <UserInfo :user-info="comment.creator">
            <template
              v-slot="{
                userProjectTeamRole: teamRole,
                userNameTitleAccordingToRole: userNameTitle
              }">
              <p
                data-test="project_comment_author_name"
                class="ma-0 fs-14 font-fam-poppins">
                {{ userNameTitle }}
                <span v-if="teamRole">
                  {{ teamRole }}
                </span>
              </p>
            </template>
          </UserInfo>
        </div>

        <div class="comment-date">
          <!-- time -->
          <p
            data-test="project_comments_time"
            class="small-p title-in-modal mb-0 d-flex">
            {{ setDateOfComment(comment) }}
            {{ comment.dateModified ? ' (edited)' : '' }}
          </p>

          <!-- edit -->
          <v-icon
            v-if="isShowActionIcon(comment) && comment.commentType !== 'attachment'"
            color="black"
            size="20"
            @click="editRowComment(comment)">
            mdi-pencil
          </v-icon>

          <!-- delete -->
          <v-icon
            v-if="isShowActionIcon(comment)"
            color="red"
            size="20"
            @click="deleteRowComment(comment)">
            mdi-trash-can
          </v-icon>

          <!-- special icon for budget + private + attachment -->
          <!-- <v-icon
            v-if="setCommentIcon(comment) !== ''"
            class="pointer"
            size="20">
            {{ setCommentIcon(comment) }}
          </v-icon> -->
          <v-img
            v-if="setCommentIcon(comment)"
            :src="setCommentIcon(comment)"
            aspect-ratio="1"
            class="pointer"
            width="20" />

          <!-- resolve comment -->
          <v-tooltip
            v-if="!isDisabledComments"
            bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                :color="comment.cellCommentResolved ? 'green' : 'darkGrey'"
                data-test="project_comment_resolve_button"
                size="20"
                v-bind="attrs"
                v-on="on"
                @click="$emit('manageCellCommentResolved', comment)">
                mdi-checkbox-marked-circle
              </v-icon>
            </template>
            <span>{{ setResolveTooltip(comment) }}</span>
          </v-tooltip>
        </div>
      </div>

      <!-- comment body -->
      <div class="d-flex flex-column comment-file">
        <!-- related to field -->
        <p
          v-if="comment.cellPointer"
          class="small-p title-in-modal ma-0">
          Related to <strong>{{ comment.cellPointer }}</strong>
        </p>

        <!-- comment text -->
        <p
          v-if="comment.commentType !== 'attachment'"
          v-safe-html="formattedTextareaValueForDiv(comment) || ''"
          v-linkified
          data-test="project_comment_text"
          class="mb-0"
          :class="{
            'resolved-comment' : comment.cellCommentResolved,
            'resolved-comment__expanded': expandedComments.includes(comment.id),
          }"
          style="overflow-wrap: anywhere" />

        <!-- if attachment -->
        <div
          v-else-if="comment.commentType === 'attachment'"
          target="_blank"
          :class="{
            'resolved-comment' : comment.cellCommentResolved,
            'resolved-comment__expanded': expandedComments.includes(comment.id),
          }"
          class="comment-file-container">
          <!-- attachment inside comment layout -->
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <div
                class="comment-file-container__content pointer"
                v-bind="attrs"
                v-on="on">
                <img
                  v-if="!comment.thumbnailId || !getThumbnail(comment.thumbnailId)"
                  src="@/assets/icons/file-icon.svg">

                <!-- attachment name -->
                <p
                  v-if="!comment.thumbnailId"
                  class="small-p mb-0 overflow-dots"
                  style="max-width: 105px">
                  {{ getFileName(comment) }}
                </p>

                <img
                  v-if="comment.thumbnailId"
                  v-lazy="{
                    src: getThumbnail(comment.thumbnailId),
                    loading: require('@/assets/icons/file-icon.svg'),
                  }"
                  class="img-screen thumbnail-img">

                <!-- download btn -->
                <a
                  :href="`/getFile?fileId=${comment.documentId}&force_update=true`"
                  @click.prevent="downloadFile(comment)">
                  Download
                </a>
              </div>
            </template>
            <span>
              {{ getFileName(comment) }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <p
      v-if="data.nextTokenForUnread && !type"
      class="medium-p mb-0 mt-2 mx-8 text-center show-more-comments"
      @click="$emit('getComments', {
        scanIndexForward: true,
      })">
      <span class="pointer">
        Show the Latest Comments
      </span>
    </p>
  </div>
</template>
<script>
import {
  mapState,
  mapActions,
  mapGetters,
} from 'vuex';
import linkify from 'vue-linkify';

import DollarIcon from '@/assets/icons/dollar-icon.svg';
import LoadingFileSvg from '@/assets/icons/file-icon.svg';
import LockIcon from '@/assets/icons/lock-icon.svg';
import LinkIcon from '@/assets/icons/link-icon.svg';

import {
  COMMENT_RESOURCE_TYPE, COMMENTS_RESOURCES,
} from '@/constants/comments';

import CommentsResources from '@/mixins/CommentsResources';

export default {
  name: 'AppCommentsList',
  components: {
    UserInfo: () => import('@/components/App/AppShareModal/AppShareModalUserInfo'),
  },
  directives: {
    linkified: linkify,
  },
  mixins: [CommentsResources],
  props: {
    allowResolveComments: {
      type: Boolean,
      default: false,
    },
    filesSrc: {
      type: Object,
      default: () => {
      },
    },
    editMode: {
      type: Boolean,
      default: true,
    },
    editedRowId: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: () => ({
        comments: [],
        nextToken: null,
      }),
    },
    isDisabledComments: {
      type: Boolean,
      default: false,
    },
    rowId: {
      type: String,
      default: null,
    },
    scrolledComment: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    hoverRowId: null,
    expandedComments: [],
  }),
  computed: {
    ...mapGetters('Comments', ['slantV2']),
    ...mapState(['avatars', 'userInfo']),
    ...mapState('Comments', ['hideResolvedCollectionCommentsV2', 'collectionCommentsListV2', 'hideResolvedProjectCommentsV2', 'projectCommentsListV2']),
    attachmentPreviewUrl() {
      return this.previewUrl ?? LoadingFileSvg;
    },
    filterComments() {
      if (this.hideResolvedComments) {
        return this.data.comments.filter(el => !el.commentResolved);
      }
      return this.data.comments;
    },
    hideResolvedComments() {
      const { currentResource } = this;
      return this[currentResource.hideResolvedToggle];
    },
    slant() {
      return this.slantV2(this.rowId, COMMENTS_RESOURCES[COMMENT_RESOURCE_TYPE.COLLECTION]);
    },
  },
  destroyed() {
    this.expandedComments = [];
    this.$emit('update:filesSrc', {
    });
  },
  methods: {
    ...mapActions(['getFileFromS3']),
    async downloadFile(comment) {
      this.getFileFromS3({
        key: this.getFileUrlForDownload(comment),
        forceUpdate: true,
        fileName: this.getFileName(comment),
      });
    },
    commentsUnread({
      commentId = null, creator = {
      },
    } = {
    }) {
      const {
        type,
        slant,
        userInfo,
        allowResolveComments,
      } = this;
      const { unreadMessageIDs } = slant ?? [];
      const unreadComment = unreadMessageIDs?.includes(commentId);
      const useInGeneralChat = allowResolveComments;
      return unreadComment && (!type || useInGeneralChat) && userInfo.email !== creator?.email;
    },
    deleteComment(comment) {
      this.$emit('deleteComment', comment);
    },
    editComment(comment) {
      this.$emit('editComment', comment);
    },
    formattedTextareaValueForDiv(comment) {
      try {
        if (comment.message) {
          let formattedString = comment.message.replace(/\r?\n/g, '<br>');
          if (comment?.mentioned) {
            comment.mentioned.forEach((user) => {
              if (user) {
                const getAllIndexes = (arr, val) => {
                  const indexes = [];
                  let i = -1;
                  while ((i = arr.indexOf(val, i + 1)) != -1) {
                    indexes.push(i);
                  }
                  return indexes;
                };
                const indexes = getAllIndexes(formattedString, `@${user.email}`);
                indexes.forEach((foundIndex, indexOfArr) => {
                  let index = foundIndex;
                  if (indexOfArr !== 0) {
                    index = foundIndex + indexOfArr * 7;
                  }
                  if (index >= 0) {
                    formattedString = `${formattedString.slice(0, index)}<a>${formattedString
                      .slice(index, index + user.email.length + 1)}</a>${formattedString
                      .slice(index + user.email.length + 1)}`;
                  }
                });
              }
            });
          }
          return formattedString;
        }
        return '';
      } catch (e) {
        console.log(e);
      }
    },
    getCreatorColor({ comment }) {
      return this.getCreatorProp({
        comment, prop: 'id',
      })?.substring(0, 6);
    },
    getCreatorProp({ comment, prop = 'picture' }) {
      return comment.creator && comment.creator[prop];
    },
    getFileUrlForDownload(comment) {
      const file = this.renderAttachment(comment);
      if (!file?.key) {
        return '';
      }
      return file?.key.split('/').slice(-2).join('/');
    },
    getFileName(comment) {
      return this.renderAttachment(comment)?.filename || '';
    },
    getThumbnail(id) {
      return this.filesSrc[id];
    },
    getThumbnailObject(comment) {
      const thumbnail = comment?.attachments[0]?.thumbnails;
      return thumbnail?.small || thumbnail?.large || null;
    },
    getThumbnailObjectKey(comment) {
      return this.getThumbnailObject(comment)?.key || '';
    },
    isShowActionIcon(comment) {
      const userId = this.userInfo.sub;
      const { creator } = comment;
      const { id: creatorId } = creator;
      if (!creatorId || !userId) return false;
      return userId === creatorId;
    },
    isShowResolveAction({ commentResolved }) {
      return !commentResolved;
    },
    manageCellCommentResolved(comment) {
      this.$emit('manageCellCommentResolved', comment);
    },
    manageRevealing({ id }) {
      const { expandedComments } = this;
      if (expandedComments.includes(id)) {
        this.expandedComments = expandedComments.filter(commentId => commentId !== id);
      } else {
        this.expandedComments = [...expandedComments, id];
      }
    },
    renderAttachment(comment) {
      return comment?.attachments[0];
    },
    setCommentIcon({ commentType, privacy }) {
      let icon = '';
      if (privacy === 'private') {
        icon = commentType === 'offer' ? DollarIcon : LockIcon;
      } else if (commentType === 'attachment') {
        icon = LinkIcon;
      }
      return icon;
    },
    setDateOfComment(comment) {
      const { dateModified, createdDate } = comment;
      const date = dateModified ? dateModified : createdDate;
      return this.$moment(date).fromNow();
    },
    setExpandedIcon(id) {
      return this.expandedComments.includes(id) ?
        'mdi-chevron-up' : 'mdi-chevron-down';
    },
    setResolveIcon(comment) {
      return this.isShowResolveAction(comment) ?
        'mdi-checkbox-marked-circle' : 'mdi-history';
    },
    setResolveTooltip(comment) {
      if (!this.editMode) {
        return 'Go to row comments to resolve or unresolve the comment';
      }
      return this.isShowResolveAction(comment) ?
        'Resolve' : 'Re-open';
    },
    showCellPointerBlock({ cellPointer }) {
      return cellPointer;
    },
  },
};
</script>
<style scoped lang="scss">
.comments-wrapper {
  &-border-items {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0;
    padding: 24px;
    border-radius: 10px 10px 10px 0px;

    &:nth-child(odd) {
      background: var(--v-lightGrey-base);
    }

    &__edited {
      border: 1px solid var(--v-blue-base);
      opacity: 0.8;
    }

    &__scrolled-comment {
      background: #f3f3f9;
    }
  }

  .comment-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;
  }

  .comment-author {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .comment-date {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
  }

  .show-more-comments {
    color: var(--v-blue-base);
    &:hover {
      opacity: 0.6;
    }
  }

  .comment-file {
    display: grid;
    justify-content: start;
    gap: 5px;

    &-container {
      max-width: 105px;

      &__content {
        display: grid;

        img {
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
