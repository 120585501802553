<template>
  <v-icon
    color="black"
    @click="showExpandedDialog">
    mdi-fullscreen
  </v-icon>
</template>
<script>
import { mapMutations } from 'vuex';
import { TYPE_READONLY } from '@/constants';
export default {
  name: 'ProductCellExpandedView',
  methods: {
    ...mapMutations('Collections', ['changeProductModalVariant']),
    showExpandedDialog() {
      // reset the product modal view
      this.changeProductModalVariant(TYPE_READONLY);
      this.$emit('showExpandedDialog');
    },
  },
};
</script>
