<template>
  <div class="collection-action-button__header-text">
    <v-btn
      :target="target"
      :href="link"
      color="blue"
      class="white--text"
      elevation="0"
      @click="onClickLink">
      <span class="collection-action-button__header-span">
        <template v-if="!useFollowLaterTradeshowFlow">
          Follow
        </template>
        <template v-else>
          Follow and save collection for later
        </template>
      </span>
    </v-btn>
    <app-dialog
      v-model="dialog"
      content-class="v-dialog__form"
      width="637">
      <div class="dialog-content">
        <v-card>
          <v-card-title>
            <div class="d-flex align-center">
              Follow This Collection
            </div>
            <span
              class="sourcery__icon-wrapper black"
              @click="dialog = false">
              <v-icon
                color="white"
                size="15">
                mdi-close
              </v-icon>
            </span>
          </v-card-title>
          <v-card-text class="text-center d-flex flex-column align-center">
            <div class="w-full">
              <v-form ref="tradeShowFlowForm">
                <v-text-field
                  v-model="email"
                  :rules="[rules.required, rules.email]"
                  validate-on-blur
                  solo
                  single-line
                  dense
                  placeholder="email"
                  class="w-full" />
              </v-form>
            </div>
            <div class="d-flex gap-3">
              <v-btn
                :disabled="sendingEmail"
                outlined
                @click="dialog = false">
                Cancel
              </v-btn>
              <v-btn
                :loading="sendingEmail"
                color="blue"
                class="white--text"
                elevation="0"
                @click="sendEmail">
                Send
              </v-btn>
            </div>
            <div class="text-uppercase my-6 or-div">
              <span>or</span>
            </div>
            <v-btn
              :disabled="sendingEmail"
              :href="link"
              :target="target"
              color="blue"
              class="white--text"
              elevation="0">
              Save on Sourcery!
            </v-btn>
          </v-card-text>
        </v-card>
      </div>
    </app-dialog>
  </div>
</template>
<script>
import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import { Auth } from 'aws-amplify';
import collections from '@/services/graphql/collections';
export default {
  name: 'FollowLinkBtn',
  props: {
    link: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      default: 'blank',
    },
  },
  data() {
    return {
      dialog: false,
      email: '',
      rules: {
        required: value => !!value || 'This field is required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'Invalid e-mail.';
        },
      },
      sendingEmail: false,
    };
  },
  computed: {
    ...mapGetters('FeatureFlags', ['useFollowLaterTradeshowFlow']),
    ...mapState('Collections', ['sharedLink']),
    ...mapState(['activeHeader']),
  },
  async mounted() {
    if (this.$route?.query['follow']) {
      try {
        await Auth.currentAuthenticatedUser();
        window.open(this.link, '_self');
      } catch (error) {
        if (!this.useFollowLaterTradeshowFlow) return;
        this.dialog = true;
      }
    }
  },
  methods: {
    ...mapActions(['handleError']),
    async onClickLink(e) {
      if (!this.useFollowLaterTradeshowFlow) return;
      try {
        await Auth.currentAuthenticatedUser();
      } catch (error) {
        if (!this.useFollowLaterTradeshowFlow) return;
        e.preventDefault();
        this.dialog = true;
      }
    },
    async sendEmail() {
      try {
        this.sendingEmail = true;
        if (!this.$refs.tradeShowFlowForm.validate()) return;
        window.localStorage.setItem('followAndSaveCollectionEmail', this.email);
        await collections.tradeShowSendEmail({
          email: this.email,
          collectionName: this.activeHeader?.name,
          quickLink: window.location.href,
        });
      } catch (e) {
        this.handleError(e);
      } finally {
        this.dialog = false;
        this.sendingEmail = false;
      }
    },
  },

};
</script>
<style scoped>
.w-full{
  width: 100%;
}
.or-div span {
  font-size: 22px;
}
</style>
