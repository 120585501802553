<template>
  <div class="carousel ma-5">
    <!-- add photos icon -->
    <CollectionAddPhotos
      v-if="showAddBtn"
      :images="images"
      v-on="$listeners" />

    <!-- carousel -->
    <Swiper
      v-show="images.length"
      ref="mySwiper"
      :options="swiperOption">
      <SwiperSlide
        v-for="(item, index) in images"
        :key="index"
        class="swiper-slide">
        <img
          :src="item.url"
          @click="showFullSizeCarousel({
            index: index,
          })">
      </SwiperSlide>
      <template #button-prev>
        <div
          class="swiper-button-prev"
          @click="prev()">
          <v-icon
            color="white"
            size="40">
            mdi-chevron-left
          </v-icon>
        </div>
      </template>
      <template #button-next>
        <div
          class="swiper-button-next"
          @click="next()">
          <v-icon
            color="white"
            size="40">
            mdi-chevron-right
          </v-icon>
        </div>
      </template>
      <div
        slot="button-next"
        class="swiper-button-next"
        @click="next()" />
    </Swiper>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import CollectionAddPhotos from '@/components/CollectionDetails/CollectionAddPhotos';

import {
  Swiper, SwiperSlide, directive,
} from 'vue-awesome-swiper';

export default {
  name: 'CollectionsPhotos',
  components: {
    CollectionAddPhotos,
    Swiper, SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    showAddBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filesForPreview: [],
      startingImageIndex: 0,
      swiperOption: {
        centerInsufficientSlides: true,
        spaceBetween: 20,
        slidesPerView: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          [this.$vuetify.breakpoint.thresholds.lg]: {
            slidesPerView: 4,
          },
          [this.$vuetify.breakpoint.thresholds.md]: {
            slidesPerView: 3,
          },
          [this.$vuetify.breakpoint.thresholds.sm]: {
            slidesPerView: 2,
          },
        },
      },
    };
  },
  methods: {
    ...mapMutations(['setFilesForPreview', 'setFilesForPreviewIndex']),
    next() {
      this.$refs.mySwiper.$swiper.slideNext();
    },
    prev() {
      this.$refs.mySwiper.$swiper.slidePrev();
    },
    showFullSizeCarousel({ index = 0 } = {
    }) {
      this.setFilesForPreview(this.images);
      this.setFilesForPreviewIndex(index);
    },
  },
};
</script>
<style lang="scss">
@import '~swiper/swiper-bundle.min';

.carousel {
  position: relative;

  .swiper-wrapper {
    height: 300px;

    .swiper-slide {
      border-radius: 15px;
      overflow: hidden;
      background-color: var(--v-lightGrey-base);
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    visibility: hidden;
    display: grid;
    align-items: center;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    background: var(--v-black-base);

    &:after {
      display: none;
    }
  }

  .swiper-container:hover .swiper-button-prev,
  .swiper-container:hover .swiper-button-next {
    visibility: visible;
  }
}
</style>
